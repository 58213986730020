/*
 * @Author: your name
 * @Date: 2021-06-09 09:59:11
 * @LastEditTime: 2021-06-16 15:01:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qianyue\src\components\company\Message.js
 */
let CommentDomain = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

  /**
  * 获取所有留言
  * @param {any} success
  * @param {any} error
  */
    this.AllComment =function(success,error)
    {
        
        var url =ServiceBaseAddress +'/api/Comment';
        tokenClient.Get(url,true,null,null,
            function(data){
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            },error);
    }
    /**
     * 新增留言
     * @param {any} content 留言
     * @param {any} userName 姓名
     * @param {any} mail 邮箱
     * @param {any} phone 电话
     * @param {any} success
     * @param {any} error
     */
         this.AddComment = function (content, userName, mail,phone,success, error) {
            var url = ServiceBaseAddress + '/api/Comment';
            var param = {
                userName: userName,
                mail: mail,
                content: content,
                phone: phone,
            };
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
}
export { CommentDomain }