<template>
    <div class="contact xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>联系我们</h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>联系我们</a></p>
                </div>
            </div>
        </div>
        <div class="contact-wrap">
            <div class="contact-map" style="margin-bottom: 20px;">
                <baidu-map class="map" :center="center" :zoom="zoom" @ready="handler" @click="getClickInfo" :scroll-wheel-zoom='true'>
                </baidu-map>
            </div>
            <h3 class="tit">请认真填写以下信息，我们会第一时间给予反馈!千越医疗真诚期待您的留言！</h3>
            <el-form :model="Comment" class="contact-form" label-width="120px" ref="contactUsForm" :rules="rules">
                <el-row :gutter="150">
                    <el-col :span="15">
                        <h3 class="form-tit">您的留言   Your message</h3>
                        <el-form-item  label="姓名(Name)" prop="userName">
                            <el-input v-model="Comment.userName"></el-input>
                        </el-form-item>
                        <el-form-item label="电话(Tel)" prop="phone">
                            <el-input v-model="Comment.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱(Mailbox)">
                            <el-input v-model="Comment.mail" ></el-input >
                        </el-form-item>
                        <el-form-item label="留言(Comment)" prop="content">
                            <el-input type="textarea" v-model="Comment.content"></el-input>
                        </el-form-item>
                        <!-- <el-form-item  label="姓名(Name)">
                            <el-input ref="Name" v-model="Comment.userName"></el-input>
                        </el-form-item>
                        <el-form-item label="电话(Tel)">
                            <el-input ref="phone" v-model="Comment.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱(Mailbox)">
                            <el-input v-model="Comment.mail" ></el-input >
                        </el-form-item>
                        <el-form-item label="留言(Comment)">
                            <el-input type="textarea" ref="content" v-model="Comment.content" prop="content"></el-input>
                        </el-form-item> -->
                        <el-form-item>
                            <el-button type="primary" @click="SaveComment('contactUsForm')">提交</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <h3 class="form-tit">联系我们   Contact us</h3>
                        <dl class="foot-us" style="margin-top: 20px;">
                            <!--<dt class="clearfix"><div class="icon"><i class="iconfont">&#xe61b;</i></div><p>18639512888（陈总）</p></dt>
                            <dt class="clearfix"><div class="icon"><i class="iconfont">&#xe61b;</i></div><p>18639539595（周总）</p></dt>-->
                            <dt class="clearfix"><div class="icon"><i class="iconfont">&#xe61a;</i></div><p>3248344350@qq.com</p></dt>
                            <dt class="clearfix"><div class="icon"><i class="iconfont">&#xe62e;</i></div><p>河南省-郑州市-二七区  大学路与政通路交叉口升龙天玺6楼622室</p></dt>
                        </dl>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
    import { Form } from 'element-ui';
    import ftCom from '../../components/footer';
    import nhCom from "../../components/navheader";
    import { CommentDomain } from '../../components/company/Comment';
    export default {
        name: "contactUs",
        components: {
            ftCom,
            nhCom,
        },
        data(){
            var resultDomainTemp = new CommentDomain(this.TokenClient, this.Services.Authorization)
            return{
                Comment:{
                    content:'',
                    userName:'',
                    mail:'',
                    phone:'',
                    result:'',
                },
                ResultDomain:resultDomainTemp,
                center: {lng: 109.45744048529967, lat: 36.49771311230842},
                zoom: 13,
                rules: {
                    userName: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: '请输入联系电话', trigger: 'blur' },
                        { pattern:/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur"}
                    ],
                    content: [
                        { required: true,  message: '请填写您的留言', trigger: 'blur' }
                    ]
                }
            }
        },
        methods:{
            SaveComment(formName){
                var _this = this;
                var item = _this.Comment;
                // if(item.userName ==""){
                //     _this.$alert('请填写姓名', '提示', {
                //     confirmButtonText: '确定'});
                //     _this.$refs['Name'].focus();
                //     return false;
                // }
                // if(item.phone =="" && item.mail =="")
                // {
                //     _this.$alert('请填写联系电话或者邮箱！', '提示', {
                //     confirmButtonText: '确定'});
                //     _this.$refs['phone'].focus();
                //     return false;
                // }
                // if(item.content ==""){
                //     _this.$alert('请填写您的留言', '提示', {
                //     confirmButtonText: '确定'});
                //     _this.$refs['content'].focus();
                //     return false;
                // }
                 _this.$refs[formName].validate((valid) => {
                    if (valid) {
                            _this.ResultDomain.AddComment(item.content, item.userName, item.mail, item.phone,function (data) {
                            _this.$alert('提交成功！', '提示', {
                            confirmButtonText: '确定'});
                        },
                        function (err) {
                            console.log(err);
                        }
                    )
                    }else {
                        console.log("验证未通过");
                        return false;
                    }
                 })
            },
            handler ({BMap, map}) {
                // 初始化地图,设置中心点坐标
                var point = new BMap.Point(113.650992,34.731295)
                map.centerAndZoom(point, 13)

                // 添加鼠标滚动缩放
                map.enableScrollWheelZoom();
                // 添加缩略图控件
                map.addControl(new BMap.OverviewMapControl({isOpen:false,anchor:BMAP_ANCHOR_BOTTOM_RIGHT}));
                // 添加缩放平移控件
                map.addControl(new BMap.NavigationControl());
                //添加比例尺控件
                map.addControl(new BMap.ScaleControl());
                //添加地图类型控件
                //map.addControl(new BMap.MapTypeControl());

                //设置标注的图标
                var icon = new BMap.Icon("http://api.map.baidu.com/img/markers.png",new BMap.Size(23,25));
                //设置标注的经纬度
                var marker = new BMap.Marker(new BMap.Point(113.650992,34.731295),{icon:icon});
                //把标注添加到地图上
                map.addOverlay(marker);
                var content = "<table>";
                content = content + "<tr><td> 公司名称：河南千越医疗科技有限公司</td></tr>";
                content = content + "<tr><td> 地点：河南省-郑州市-二七区升龙天玺8号楼<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;河南省-漯河市-郾城区</td></tr>";
                content += "</table>";
                var infowindow = new BMap.InfoWindow(content);
                // 图标点击的时候显示标注
                /*marker.addEventListener("click",function(){
                    this.openInfoWindow(infowindow);
                });*/
                // 标注默认显示
                var infoWindow = new BMap.InfoWindow(content) // 创建信息窗口对象
                map.openInfoWindow(infoWindow, point)
            },
            getClickInfo (e) {
                this.center.lng = e.point.lng
                this.center.lat = e.point.lat
            }
        }
    }
</script>

<style scoped>
    .map {
        width: 1200px;
        height: 500px;
    }
    .contact{
        background: #f7f7f7;
    }
    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg")center no-repeat;
    }

    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
    /*----------*/
    .contact-wrap{
        width: 1200px;
        margin: 50px auto 80px;
    }
    .contact-wrap .tit{
        font-weight: normal;
        font-size: 16px;
        color: #666666;
        background-color: #ececec;
        height: 56px;
        line-height: 56px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .contact-form .form-tit{
        margin: 15px 0;
        font-weight: normal;
        color: #666666;
        font-size: 18px;
    }
    .contact-form .foot-us dt{
        margin: 10px 0;
        line-height: 36px;
    }
    .contact-form .foot-us dt .icon{
        float: left;
        width: 15px;
    }
    .contact-form .foot-us dt .icon i{
        font-size: 20px;
        color: #999999;
    }
    .contact-form .foot-us dt p{
        float: right;
        padding-right: 20px;
        width: calc(100% - 50px);
        text-align: left;
        font-size: 16px;
        color: #999999 ;
    }
</style>
<style>
    .contact-form .el-textarea__inner{
        background-color: #e6e6e6;
        font-size: 16px;
        height: 100px;
    }
    .contact-form .el-input-group__append, .el-input-group__prepend{
        background-color: #f7f7f7;
        border: none;
        width: 110px;
        color: #666666;
        font-size: 16px;
    }
    .contact-form .el-input__inner{
        background-color: #e6e6e6;
        font-size: 16px;
    }
    .contact-form .el-form-item__label{
        font-size: 16px;
        color: #666666;
    }
    .contact-form .el-button{
        width: 156px;
        border-radius: 0px;
        padding: 15px 20px;
        font-size: 16px;
    }
</style>
